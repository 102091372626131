.product-page {
  max-width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 2%;
  font-family: Arial, sans-serif;
}
.product-details{
  max-width: 80%;
}
.product-header {
  text-align: center;
  margin-bottom: 2%;
}
.product-header h1{
  font-family: "Ubuntu", sans-serif;
  font-weight: 550;
  font-style: normal;
}
.price {
  font-size: 2.4vw;
  color: #ffad40;
  margin: 1% 0;
}

.order-button {
  background-color:#ffad40;
  color: white;
  padding: 1% 2%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.product-header .hotline {
  display: inline-block;
  margin: 0.5%;
  padding: 1% 2%;
  background-color:#ffad40;
  text-decoration: none;
  color: black;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.product-header .hotline a:hover{
  color: blue;
}

.product-content {
  max-width: 72%;
  gap: 3%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 2%;
}

.product-image img {
  width: 30%;
}

.product-details {
  margin-top: 1vw;
  max-width: 60%;
  color: #8b5e22;
}

.shopping-links a {
  display: inline-block;
  margin: 0.5%;
  padding: 1% 2%;
  background-color:#ffad40;
  text-decoration: none;
  color: black;
  border-radius: 5px;
}

.product-features {
  list-style-type: none;
  padding: 0;
  color:#8b5e22;
}

.product-features li {
  word-wrap: break-word; /* Cho phép các từ dài tự động ngắt dòng */
  white-space: normal; /* Đảm bảo văn bản ngắt dòng khi cần */
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
  overflow-wrap: break-word;
  margin: 1% 0;
}

.product-gallery img {
  width: 100%;
  max-width: 60%;
  margin: 0 auto;
  display: block;
}

.contact {
  text-align: center;
  margin-top: 2%;
}

.contact h3 {
  background-color:#ffad40;
  color: white;
  display: inline-block;
  padding: 1% 2%;
  border-radius: 5px;
}

.product-header label {
  font-size: 1.6vw;
  margin-right: 1%;
}

.product-header select {
  padding: 0.5% 1%;
  font-size: 1.6vw;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  margin-right: 1.5%;
  cursor: pointer;
}

.product-header select:focus {
  border-color:#ffad40;
}

@media screen and (max-width: 768px) {
  .product-content {
    flex-direction: column;
    align-items: center;
    gap: 2%;
  }

  .product-image img {
    width: 80%;
    margin: 0 auto;
  }

  .product-details {
    max-width: 90%;
    text-align: left;
    padding-top: 20px;

  }
  .product-features img{
    width: 100%;
    margin-top: 10px;
  }
  .product-header h1{
    font-size: 100%;
  }

  .product-header .price {
    font-size: 100%;
    font-weight: 700;
  }

  .order-button {
    width: 100%;
    padding: 1.5%;
  }

  .shopping-links a {
    width: 100%;
    margin: 1% 0;
    text-align: center;
  }

  .product-header label, .product-header select {
    width: 100%;
    margin-bottom: 1%;
  }

  .slideshow-container {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .product-header h1 {
    font-size: 2vw;
  }

  .price {
    font-size: 1.8vw;
  }

  .order-button {
    padding: 1%;
  }

  .shopping-links a {
    padding: 0.8% 1%;
  }

  .product-header label, .product-header select {
    font-size: 1.4vw;
  }
}
/* Màn hình 820px */
@media screen and (max-width: 820px) {
  .product-header h1 {
    font-size: 2.4vw;
  }

  .price {
    font-size: 2.2vw;
  }

  .order-button {
    width: 100%;
    padding: 1.2%;
  }

  .shopping-links a {
    width: 100%;
    margin: 1.2% 0;
    text-align: center;
  }
}

/* Màn hình 768px */
@media screen and (max-width: 768px) {
  .product-content {
    flex-direction: column;
    align-items: center;
    gap: 2%;
  }

  .product-image img {
    width: 80%;
    margin: 0 auto;
  }

  .product-details {
    max-width: 100%;
  }

  .product-header h1 {
    font-size: 2.2vw;
  }

  .price {
    font-size: 2vw;
  }

  .order-button {
    width: 100%;
    padding: 1.5%;
  }

  .shopping-links a {
    width: 100%;
    margin: 1% 0;
    text-align: center;
  }

  .product-header label, .product-header select {
    width: 100%;
    margin-bottom: 1%;
  }

  .slideshow-container {
    width: 100%;
  }
}

/* Màn hình 430px */
@media screen and (max-width: 430px) {
  .product-header h1 {
    font-size: 2.5vw;
  }

  .price {
    font-size: 2.3vw;
  }

  .order-button {
    width: 100%;
    padding: 1.5%;
  }

  .shopping-links a {
    width: 100%;
    margin: 1.5% 0;
    text-align: center;
  }

  .product-header label, .product-header select {
    font-size: 1.5vw;
  }
}

/* Màn hình 414px */
@media screen and (max-width: 414px) {
  .product-header h1 {
    font-size: 2.6vw;
  }

  .price {
    font-size: 2.4vw;
  }

  .order-button {
    width: 100%;
    padding: 1.6%;
  }

  .shopping-links a {
    width: 100%;
    margin: 1.6% 0;
    text-align: center;
  }

  .product-header label, .product-header select {
    font-size: 1.6vw;
  }
}

/* Màn hình 412px */
@media screen and (max-width: 412px) {
  .product-header h1 {
    font-size: 2.7vw;
  }

  .price {
    font-size: 2.5vw;
  }

  .order-button {
    width: 100%;
    padding: 1.7%;
  }

  .shopping-links a {
    width: 100%;
    margin: 1.7% 0;
    text-align: center;
  }

  .product-header label, .product-header select {
    font-size: 1.7vw;
  }
}

/* Màn hình 390px */
@media screen and (max-width: 390px) {
  .product-header h1 {
    font-size: 2.8vw;
  }

  .price {
    font-size: 2.6vw;
  }

  .order-button {
    width: 100%;
    padding: 1.8%;
  }

  .shopping-links a {
    width: 100%;
    margin: 1.8% 0;
    text-align: center;
  }

  .product-header label, .product-header select {
    font-size: 1.8vw;
  }
}

/* Màn hình 375px */
@media screen and (max-width: 375px) {
  .product-header h1 {
    font-size: 3vw;
  }

  .price {
    font-size: 2.8vw;
  }

  .order-button {
    width: 100%;
    padding: 1.9%;
  }

  .shopping-links a {
    width: 100%;
    margin: 2% 0;
    text-align: center;
  }

  .product-header label, .product-header select {
    font-size: 2vw;
  }
}

/* Màn hình 360px */
@media screen and (max-width: 360px) {
  .product-header h1 {
    font-size: 3.2vw;
  }

  .price {
    font-size: 3vw;
  }

  .order-button {
    width: 100%;
    padding: 2%;
  }

  .shopping-links a {
    width: 100%;
    margin: 2.2% 0;
    text-align: center;
  }

  .product-header label, .product-header select {
    font-size: 2.2vw;
  }
}
