svg {
	width: 120px;
}
.menu-icon{
	display: none;
}
#hamburger{
	margin: 0;
	padding: 0;
	width: 50px;
	height: 45px;
}
#top-line,
#bottom-line,
#middle-line {
	transform-box: fill-box;
	transform-origin: center;
}

svg:hover {
	#top-line {
		animation: down-rotate 0.6s ease-out both;
	}
	#bottom-line {
		animation: up-rotate 0.6s ease-out both;
	}
	#middle-line {
		animation: hide 0.6s ease-out forwards;
	}
}

@keyframes up-rotate {
	0% {
		animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53);
		transform: translateY(0px);
	}
	30% {
		transform-origin: center;
		animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
		transform: translateY(-10px);
	}
	100% {
		transform-origin: center;
		transform: translateY(-10px) rotate(45deg) scale(0.9);
	}
}

@keyframes down-rotate {
	0% {
		animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53);
		transform: translateY(0px);
	}
	30% {
		transform-origin: center;
		animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
		transform: translateY(10px);
	}
	100% {
		transform-origin: center;
		transform: translateY(10px) rotate(-45deg) scale(0.9);
	}
}

@keyframes hide {
	29% {
		opacity: 1;
	}
	30% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@media screen and (max-width: 768px) {
	.menu-icon{
		display: block;
	}
  }