.introduce {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2%;
}
.introduce-title {
  text-align: center;
  background: linear-gradient(to bottom, white, #f0f8ff);
  padding: 50px 20px;
  margin-bottom: 20px;
  max-width:1055px;
  border-radius: 22.721px;
  border-bottom: 4.369px solid #ffad40;
  background: linear-gradient(180deg, #FFF 0%, #ffac408c 100%);
}

.introduce-header {
  margin-bottom: 30px;
}
.introduce-header .logo {
  height: 50px;
  margin-bottom: 30px;
}
.introduce-header h2 {
  font-size: 24px;
  font-style:bold;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-weight: 550;
  color:#ffad40;
  margin: 0;
}
.benefits {
  display: flex;
  justify-content:space-around;
  gap: 20px;
}


.benefit p {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-self: center;
  margin: 0;
}
.benefit {
  background-color: #ffad40;
  display: flex;
  padding: 10px 25px 10px 25px;
  border-radius: 10px;
  box-shadow: 0px 3.495495557785034px 10.486486434936523px 0px #A3BCFF;
  text-align: center;
}
.introduce-content {
  display: flex;
  max-width: 70%;
  flex-direction: row;
  align-items: center;
  padding-bottom: 2vw;
}

.introduce-image img {
  max-width: 100%;
  height: auto;
  margin-right: 2%;
}

.introduce-text {
  max-width: 60%;
}

.introduce-text h2 {
  color: #333;
  font-style: italic;
  font-size: 2vw;
  margin-bottom: 1%;
}

.introduce-text h1 {
  color: #ffad40;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 2.2vw;
  margin-bottom: 2%;
}

.canxi-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.canxi-icon img {
  max-width: 10%;
  height: auto;
  margin-right: 2%;
}

.canxi-description h3 {
  color: #333;
  font-style:bold;
  font-weight: 700;
  font-size: 1.5vw;
  margin-bottom: 1%;
}

.canxi-description ul {
  list-style: none;
  padding: 0;
}

.canxi-description ul li {
  color: #333;
  font-size: 1.2vw;
  margin-bottom: 1%;
}

.canxi-description ul li span {
  margin-right: 1%;
  font-size: 1.4vw;
}

@media screen and (max-width: 768px) {
  .introduce-content {
    flex-direction: column;
    max-width: 100%;
    padding: 20px;
    align-items: center;
  }
  .introduce-content .introduce-image img{
    width: 100%;
    height: auto;
  }
  .introduce-content .introduce-text{
    max-width: 100%;
    width: 100%;
    height: auto;
    text-align: left;
  }
  .introduce-content .canxi-info{
    width: 100%;
  }
  .introduce-content .introduce-title{
    max-width: 100%;
    height: auto;
  }
  .introduce-title .logo{
    width: 40%;
  }
  .introduce-title .introduce-header h2{
    font-size: 100%;
    font-weight: 900;
  }
  .benefits{
    flex-direction:column;
  }
}
@media screen and (max-width: 820px) {
  .introduce-text h2 {
    font-size: 4vw;
  }

  .introduce-text h1 {
    font-size: 5vw;
  }

  .canxi-description h3 {
    font-size: 3vw;
  }

  .canxi-description ul li {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 768px) {
  .introduce-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .introduce-image img {
    margin-right: 0;
    margin-bottom: 2%;
  }
  .introduce-text{
    width: 100%;
  }
  .introduce-text h2 {
    font-size: 4.5vw;
  }

  .introduce-text h1 {
    font-size: 5.5vw;
  }

  .canxi-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .canxi-icon img {
    margin-right: 0;
    margin-bottom: 2%;
  }

  .canxi-description h3 {
    font-size: 3.5vw;
  }

  .canxi-description ul li {
    font-size: 3vw;
  }
}

/* Màn hình 430px */
@media screen and (max-width: 430px) {
  .introduce {
    padding: 1%;
  }
  .introduce-text{
    width: 100%;
  }
  .introduce-text h2 {
    font-size: 5vw;
  }

  .introduce-text h1 {
    font-size: 6.5vw;
  }

  .canxi-description h3 {
    font-size: 4vw;
  }

  .canxi-description ul li {
    font-size: 3.5vw;
  }
}

/* Màn hình 414px */
@media screen and (max-width: 414px) {
  .introduce {
    padding: 1%;
  }
  .introduce-text{
    width: 100%;
  }
  .introduce-text h2 {
    font-size: 5.2vw;
  }

  .introduce-text h1 {
    font-size: 6.8vw;
  }

  .canxi-description h3 {
    font-size: 4.2vw;
  }

  .canxi-description ul li {
    font-size: 3.8vw;
  }
}

/* Màn hình 412px */
@media screen and (max-width: 412px) {
  .introduce {
    padding: 1%;
  }
  .introduce-text{
    width: 100%;
  }
  .introduce-text h2 {
    font-size: 5.3vw;
  }

  .introduce-text h1 {
    font-size: 7vw;
  }

  .canxi-description h3 {
    font-size: 4.3vw;
  }

  .canxi-description ul li {
    font-size: 4vw;
  }
}

/* Màn hình 390px */
@media screen and (max-width: 390px) {
  .introduce {
    padding: 1%;
  }
  .introduce-text{
    width: 100%;
  }
  .introduce-text h2 {
    font-size: 5.5vw;
  }

  .introduce-text h1 {
    font-size: 7.2vw;
  }

  .canxi-description h3 {
    font-size: 4.5vw;
  }

  .canxi-description ul li {
    font-size: 4.2vw;
  }
}

/* Màn hình 375px */
@media screen and (max-width: 375px) {
  .introduce {
    padding: 1%;
  }
  .introduce-text{
    width: 100%;
  }
  .introduce-text h2 {
    font-size: 5.8vw;
  }

  .introduce-text h1 {
    font-size: 7.5vw;
  }

  .canxi-description h3 {
    font-size: 4.8vw;
  }

  .canxi-description ul li {
    font-size: 4.5vw;
  }
}

/* Màn hình 360px */
@media screen and (max-width: 360px) {
  .introduce {
    padding: 1%;
  }
  .introduce-text{
    width: 100%;
  }
  .introduce-text h2 {
    font-size: 6vw;
  }

  .introduce-text h1 {
    font-size: 8vw;
  }

  .canxi-description h3 {
    font-size: 5vw;
  }

  .canxi-description ul li {
    font-size: 4.8vw;
  }
}
