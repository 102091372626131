/* src/index.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;1,100;1,700&family=Ubuntu:ital,wght@0,400;0,500;0,700;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;1,100;1,700&display=swap');

*{
    margin: 0;
    padding: 0;
    font-size: 17px;
    box-sizing: border-box;
    font-family: "Ubuntu", sans-serif;

  }
  html {
    max-width: 100%;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;

}

body {
  font-family: Arial, sans-serif;
  padding: 20 0; 
}
.homes{
  display: flex;
  flex-wrap: wrap;
}

.header {
  max-width: 100%;
  height: 115px;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content:space-around;
  padding: 10px 20px;
  background-color: white;
  border-bottom: 1px solid #ccc;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);

}

.header .logo img {
  height: 40px;
}

.header nav {
  display: flex;
  gap: 20px;
}

.header nav a {
  text-decoration: none;
  color: black;
  font-size: 17px;
  font-weight: 400;
  line-height: 22.5px;
  text-transform: capitalize;
}

.header nav a:hover {
  color:#ffad40;
}

.header .contact-button {
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  padding: 18px 41.25px 18px 42px;
  border: 2px solid #ffad40;
  background-color: #ffad40 !important;
  line-height: unset;
}

.header .contact-button:hover {
  background-color:#ffad40;
}

.img-slide {
  margin-top: 6.5vw;
  max-width: 100%;
}

.img-slide img {
  max-width: 100%;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .header {
    height: 85px;
    justify-content: center;
    position: relative;
  }
  
  .header .logo img {
    width: 150px;
  }
  
  .header nav {
    display: none;
  }
  .header .contact-button {
    display: none;
  }
  
  .header .menu-icon {
    display: block;
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .header nav.visible {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 85px; 
    left: 0;
    width: 100%;
    background-color: white;
    border-top: 1px solid #ccc;
    z-index: 999;
  }
  
  .header nav.visible a {
    padding: 10px 20px;
    border-bottom: 1px solid #ccc;
    text-align: center;
  }
  .img-slide img{
    min-width: 100%;
    height: auto;
  }
}
