.contact-section {
  text-align: center;
  padding: 5% 2%;
  background-color: #ffad40;
  color: white;
}

.contact-title {
  font-size: 2.4vw;
  margin-bottom: 3%;
}

.contact-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.contact-card {
  background-color: white;
  color: black;
  border-radius: 8px;
  padding: 2%;
  margin: 1%;
  width: 20%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.contact-card:hover {
  transform: translateY(-10%);
}

.contact-image {
  width: 50%;
  height: auto;
  margin-bottom: 1%;
}

.contact-description {
  font-size: 1.4vw;
  margin-bottom: 1.5%;
}

.contact-button {
  padding: 1% 1.5%;
  background-color: #ffad40;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}

.contact-button:hover {
  background-color: #ffad40;
}

@media screen and (max-width: 768px) {
  .contact-card {
    width: 40%;
  }

  .contact-title {
    font-size: 4vw;
  }

  .contact-description {
    font-size: 3vw;
  }

  .contact-button {
    padding: 2% 3%;
  }
}

@media screen and (max-width: 480px) {
  .contact-card {
    width: 80%;
  }

  .contact-title {
    font-size: 5vw;
  }

  .contact-description {
    font-size: 4vw;
  }

  .contact-button {
    padding: 3% 5%;
  }
}
/* Màn hình 375px */
@media screen and (max-width: 375px) {
  .contact-card {
    width: 90%;
  }

  .contact-title {
    font-size: 6vw;
  }

  .contact-description {
    font-size: 5vw;
  }

  .contact-button {
    padding: 4% 6%;
  }
}

/* Màn hình 390px */
@media screen and (max-width: 390px) {
  .contact-card {
    width: 85%;
  }

  .contact-title {
    font-size: 5.5vw;
  }

  .contact-description {
    font-size: 4.5vw;
  }

  .contact-button {
    padding: 3.5% 5.5%;
  }
}

/* Màn hình 412px */
@media screen and (max-width: 412px) {
  .contact-card {
    width: 80%;
  }

  .contact-title {
    font-size: 5vw;
  }

  .contact-description {
    font-size: 4.3vw;
  }

  .contact-button {
    padding: 3% 5%;
  }
}

/* Màn hình 414px */
@media screen and (max-width: 414px) {
  .contact-card {
    width: 78%;
  }

  .contact-title {
    font-size: 4.8vw;
  }

  .contact-description {
    font-size: 4.2vw;
  }

  .contact-button {
    padding: 2.8% 4.8%;
  }
}

/* Màn hình 430px */
@media screen and (max-width: 430px) {
  .contact-card {
    width: 75%;
  }

  .contact-title {
    font-size: 4.5vw;
  }

  .contact-description {
    font-size: 4vw;
  }

  .contact-button {
    padding: 2.5% 4.5%;
  }
}

/* Màn hình 768px */
@media screen and (max-width: 768px) {
  .contact-card {
    width: 50%;
  }

  .contact-title {
    font-size: 3.5vw;
  }

  .contact-description {
    font-size: 2.5vw;
  }

  .contact-button {
    padding: 2.5% 4%;
  }
}

/* Màn hình 820px */
@media screen and (max-width: 820px) {
  .contact-card {
    width: 45%;
  }

  .contact-title {
    font-size: 3vw;
  }

  .contact-description {
    font-size: 2vw;
  }

  .contact-button {
    padding: 2% 3.5%;
  }
}
