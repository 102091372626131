.ladi-container {
    padding: 2%;
    max-width: 75%;
    margin: 0 auto;
  }
  
  .ladi-container h1 {
    text-align: center;
    font-size: 2.5vw;
    color: #ffad40;
    margin-bottom: 2%;
  }
  
  .ladi-grid {
    display: grid;
    align-self: center;
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
    gap: 2%;
  }
  
  .ladi-grid h2 {
    text-align: center;
    font-size: 2.3vw;
    color: #ffad40;
  }
  
  .ladi-item {
    padding: 1.5%;
    border-radius: 1%;
  }
  
  .ladi-item img {
    max-width: 100%;
    height: auto;
    border-radius: 0.5%;
  }
  
  .ladi-item p {
    margin-top: 1%;
    font-size: 1vw;
  }
  
  @media screen and (max-width: 768px) {
    .ladi-container h1, .ladi-grid h2 {
      font-size: 100%;
      text-align: left;
    }
  
    .ladi-grid {
      grid-template-columns: repeat(auto-fit, minmax(80%, 1fr));
      gap: 3%;
    }
  
    .ladi-item {
      padding: 3%;
      text-align: left;
    }
    .ladi-item img{
      width: 100%;
    }
    .ladi-item p {
      font-size: 3vw;
    }

  }
  
  @media screen and (max-width: 480px) {
    .ladi-container h1, .ladi-grid h2 {
      font-size: 6vw;
    }
  
    .ladi-grid {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
      gap: 4%;
    }
  
    .ladi-item {
      padding: 4%;
    }
  
    .ladi-item p {
      font-size: 4vw;
    }
  }
  /* Màn hình 820px */
@media screen and (max-width: 820px) {
  .ladi-container h1, .ladi-grid h2 {
    font-size: 4.5vw;
  }

  .ladi-grid {
    grid-template-columns: repeat(auto-fit, minmax(60%, 1fr));
    gap: 2.5%;
  }

  .ladi-item p {
    font-size: 2.5vw;
  }
}

/* Màn hình 768px */
@media screen and (max-width: 768px) {
  .ladi-container h1, .ladi-grid h2 {
    font-size: 5vw;
  }

  .ladi-grid {
    grid-template-columns: repeat(auto-fit, minmax(80%, 1fr));
    gap: 3%;
  }

  .ladi-item {
    padding: 3%;
  }
  
  .ladi-item p {
    font-size: 3vw;
  }
}

/* Màn hình 430px */
@media screen and (max-width: 430px) {
  .ladi-container h1, .ladi-grid h2 {
    font-size: 5.5vw;
  }

  .ladi-grid {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 3.5%;
  }

  .ladi-item {
    padding: 3.5%;
  }
  
  .ladi-item p {
    font-size: 3.5vw;
  }
}

/* Màn hình 414px */
@media screen and (max-width: 414px) {
  .ladi-container h1, .ladi-grid h2 {
    font-size: 5.8vw;
  }

  .ladi-grid {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 4%;
  }

  .ladi-item {
    padding: 4%;
  }
  
  .ladi-item p {
    font-size: 3.8vw;
  }
}

/* Màn hình 412px */
@media screen and (max-width: 412px) {
  .ladi-container h1, .ladi-grid h2 {
    font-size: 6vw;
  }

  .ladi-grid {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 4%;
  }

  .ladi-item {
    padding: 4%;
  }
  
  .ladi-item p {
    font-size: 4vw;
  }
}

/* Màn hình 390px */
@media screen and (max-width: 390px) {
  .ladi-container h1, .ladi-grid h2 {
    font-size: 6.2vw;
  }

  .ladi-grid {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 4.5%;
  }

  .ladi-item {
    padding: 4.5%;
  }
  
  .ladi-item p {
    font-size: 4.2vw;
  }
}

/* Màn hình 375px */
@media screen and (max-width: 375px) {
  .ladi-container h1, .ladi-grid h2 {
    font-size: 6.5vw;
  }

  .ladi-grid {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 4.5%;
  }

  .ladi-item {
    padding: 4.5%;
  }
  
  .ladi-item p {
    font-size: 4.5vw;
  }
}

/* Màn hình 360px */
@media screen and (max-width: 360px) {
  .ladi-container h1, .ladi-grid h2 {
    font-size: 7vw;
  }

  .ladi-grid {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 5%;
  }

  .ladi-item {
    padding: 5%;
  }
  
  .ladi-item p {
    font-size: 4.8vw;
  }
}
