.chat-buttons {
    position: fixed;
    bottom: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1000;
}

.chat-button {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
}

.chat-button img {
    width: 30px;
    height: 30px;
}

.chat-button:hover {
    transform: scale(1.1);
}

.zalo {
    background-color: #0084ff;
}

.facebook {
    background-color: #4267B2;
}

#hotline {
    background-color:#ffac3e;
}
@media screen and (max-width: 375px) {
    .chat-button {
        width: 100%;
        height: 20px;
        bottom: 10px;
        left: 10px;
        right: 10px;
        display: flex;
        border-radius: 0;
    }


}
