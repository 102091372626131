.footer {
    background-color: #ffac4042;
    color: black;
    padding: 40px 0 0 0;
    border-top: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.footer-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-self: center;
    width: 1250px;

}

.footer-logo img {
    height: 40px;
    margin-bottom: 20px;
}

.footer-contact, .footer-links, .footer-connect {
    margin-top: 20px;
}

.footer-contact h3, .footer-links h3, .footer-connect h3 {
    margin-bottom: 10px;
    color: #ffad40;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ccc;
    margin: 0;
    padding-top: 20px;
    font-size: 14px;
    background-color: rgb(0, 0, 0);
    padding:5px 10%;
}

.footer-bottom p{
    color: #ccc;
}

.payment-methods img {
    height: 30px;
    margin-left: 10px;
}
a{
    text-decoration: none;
    color: black;
    font-size: 17px;
    font-weight: 400;
    line-height: 22.5px;
    text-transform: capitalize;
}
a:hover {
    color:#ffad40;
 }
 @media screen and (max-width: 768px) {
    html {
        max-width: 100%;
        scroll-behavior: smooth;
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-self: center;
    
    }
    .footer-top {
        flex-direction: column; /* Stack elements vertically */
        align-items: center;
    }
    .footer-logo img{
        width: 100%;
    }
    .footer-logo, .footer-contact, .footer-links, .footer-connect {
        text-align: center; /* Center text for smaller screens */
        margin-top: 10px;
    }

    .footer-bottom {
        flex-direction: column; /* Stack elements vertically */
        align-items: center;
        padding: 10px 5%; /* Reduce padding for smaller screens */
    }

    .footer-bottom p, .payment-methods {
        margin: 10px 0; /* Add margin for better spacing */
    }

    .payment-methods img {
        margin-left: 0; /* Remove left margin on smaller screens */
    }
    
}

@media screen and (max-width: 480px) {
    html {
        max-width: 100%;
        scroll-behavior: smooth;
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-self: center;
        text-align: center;
    
    }
    .footer-top {
        gap: 10px; /* Reduce gap for smaller screens */
    }

    .footer-logo, .footer-contact, .footer-links, .footer-connect {
        margin-top: 5px;
    }

    .footer-bottom p {
        font-size: 10px;
    }

    .payment-methods img {
        height: 25px; /* Reduce size for smaller screens */
    }
}